@import url("https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* ------------ VARIABLES ------------ */

:root {
  --lightText: #fff;
  --darkText: #0d1018;
  --headerColor: #fff;
  --blueColor: #2a5cb1;
}

/* ------------ GLOBALS ------------ */

* {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
  font-weight: 400;
  letter-spacing: -1px;
}

body {
  overflow-x: hidden;
}

.App {
  font-family: sans-serif;
  text-align: center;
  background-color: white;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.section {
  display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 1;
    font-family: Montserrat;
    background-color: #f9f9f9;
    /* Adjust background color as needed */
}

.home .inner,
.about .inner,
.services .inner,
.metrics .inner,
.partners .inner,
.forms .inner,
.footer .inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 84%;
  padding: 50px 0;
}

.blue {
  color: var(--blueColor) !important;
  font-weight: 600;
  text-align: left;
}

button {
  cursor: pointer;
}

button:hover {
  filter: brightness(0.8);
}

/* ------------ HEADER ------------ */
.header {
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 1000;
  box-shadow: 0 0 10px #0000002e;
}

.header .blue {
  font-weight: 600 !important;
}

.header .inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 50px;
  align-items: center;
  height: 80px;
  background: var(--headerColor);
  width: 100%;
  color: var(--lightText);
}

.header h1 {
  font-weight: 400;
  color: #000;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.logo:hover {
  opacity: 0.7 !important;
}

.logo svg {
  height: 35px;
  width: 35px;
  margin-right: 10px;
}

.logo svg path {
  fill: var(--blueColor);
}

.header button {
  background: none;
  border: 1px solid var(--blueColor);
  border-radius: 10px;
  height: 40px;
  width: 130px;
  color: var(--blueColor);
  margin: 0 5px;
  transition: 0.2s ease-in-out;
}

.header button:hover {
  opacity: 0.5 !important;
}

.header .verify {
  background: var(--blueColor);
  border: 1px solid var(--blueColor);
  color: #fff;

}

/* ------------ HOME ------------ */

.tiltContainer {
  position: absolute;
  bottom: -10vh;
  right: -10vw;
}

.homeIMG {
  width: 800px !important;
  height: 800px;
}

.home {
  background: url("https://i.ibb.co/zXMxgwx/New-Project-2022-04-09-T161829-224-2.png");
  background-size: 100% 100%;
  overflow: hidden;
  margin-top: 80px;
  box-shadow: 3000px 3000px 3000px inset #00000040;
}

.home .inner {
  min-height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  flex-direction: row;
  padding: 0;
  justify-content: flex-start;
}

.home .innerhome {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
  position: relative;
  z-index: 2;
}

.home p {
  font-size: 40px !important;
  text-align: left;
  font-weight: 600;
  color: #fff;
}

.home h2 {
  text-align: left;
  color: #fff;
  line-height: 42px;
}

.home .innerhome button {
  background: var(--blueColor);
  border: 1px solid var(--blueColor);
  border-radius: 10px;
  height: 40px;
  width: 130px;
  color: var(--lightText);
  transition-delay: 0s !important;
}

.home .innerhome button:hover {
  opacity: 0.6;
}

.home img {
  width: 400px;
}

.globe {
  position: absolute;
  z-index: 1;
  left: 28vw;
}

.globeContainer {
  position: absolute !important;
  left: 0 !important;
}

.globeContainer canvas {
  position: absolute !important;
  right: -57vw !important;
  bottom: -41vh !important;
  width: 2800px !important;
  height: 1200px !important;
}

.container {
  display: flex;
}

/* ------------ ABOUT ------------ */

.section .about {
  padding-top: 30px;
}

.about .inner {
  display: flex;
  justify-content: space-around;
  color: #fff;

  flex-direction: row;
  column-gap: 30px;
}

.lawfirm {
  width: 300px;
}

.lawfirm2 {
  width: 300px;
  display: none;
}

.about p {
  color: #000;
  font-size: 40px;
  padding: 30px;
  padding-left: 0px;
  font-weight: 600;
  text-align: left;
}

.about h2 {
  width: 100%;
  text-align: left;
  padding: 10px;
  font-size: 14px;
}

.about .container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about .container .innerContainer {
  margin: 20px;
  width: 30%;
}

.about .container .innerContainer h3 {
  text-align: center;
}

.about .container .innerContainer img {
  width: 150px;
}

.about h1 {
  color: #000;
  font-size: 16px;
  line-height: 40px;
  text-align: left;
}

/* ------------ SERVICES ------------ */

.services {
  background: rgb(237 237 237);
  z-index: 2;
}

.services .inner {
  display: flex;
  justify-content: space-around;
  padding: 50px 0;
}

.services h5 {
  color: #000;
  line-height: 30px;
  padding: 10px 0;
  font-size: 16px;
}

.services h5:first-child {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
}

.services .container {
  position: relative;
  margin: 40px 30px;
  filter: brightness(0.99);
  transition: 0.2s ease-in-out;
  max-width: 50%;
  flex: 1;
}

.cardsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.services .leftSection,
.services .rightSection {
  display: flex;
  flex: 1;
}

.titleWithIMG {
  z-index: 5;
  display: flex;
  justify-content: top;

  flex: 1;
  background: linear-gradient(13deg, #ffffff 50%, #f9f9f9 50%);
  box-shadow: 0 0 10px #0000000f;
  padding: 30px;
  border-radius: 15px;
  position: relative;
  flex-direction: column;
}

.titleWithIMG a {
  text-align: left;
}

.titleWithIMG button {
  background: none;
  border: 1px solid var(--blueColor);
  border-radius: 10px;
  height: 40px;
  width: 130px;
  color: var(--blueColor);
  margin: 0;
  transition: 0.2s ease-in-out;
}

.titleWithIMG button:hover {
  opacity: 0.5 !important;
}

.services .container img {
  width: 70px;
}

.services .innerContainer {
  flex: 1;
}

.services h2 {
  text-align: left;
  color: var(--blueColor);
  font-size: 30px;
  font-weight: 600;
}

.dark {
  filter: brightness(0);
}

/*
.services .container:hover .cardBack {
  box-shadow: 0 0 50px #0000002b;
  display: block;
  z-index: 6;
}
.services .container:hover {
  filter: brightness(1.1);
}
.services .cardBack {
  z-index: 6;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 49%);
  border-radius: 15px;
  animation: fadeIn 0.2s ease-in-out;
  backdrop-filter: blur(10px);
  display: none;
}
*/
.services h3 {
  text-align: left;
  color: #000;
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
  display: flex;
  align-items: center;
  border-radius: 15px;
  padding: 10px 0;
}

/* ------------ METRICS ------------ */
.section.metrics {
  background: #1c4995;
  margin: 50px 0 0;
  padding: 35px 0;
}

.metrics .inner {
  display: flex;
  padding: 30px 0;
}

.flex {
  display: flex;
  justify-content: space-around;
  min-width: 50%;
  align-items: center;
}

.metrics h1 {}

.metrics .container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.metrics .innerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.metrics h2 {
  font-size: 72px;
  font-weight: 700;
  color: #ffffff;
}

.metrics h4 {
  color: #ffffff82;
}

/* ------------ PARTNERS ------------ */
.partners .inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 35px 0;
  text-align: center;
}

.partners img {
  width: 200px;
  margin: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  /* Rounded corners */
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  /* Subtle shadow for images */
  /* transition: box-shadow 0.3s ease-in-out; */
  /* Smooth transition for shadow on hover */
}


.partners .container {
  display: flex;
  overflow-x: auto;
  /* Enable horizontal scrolling */
  white-space: nowrap;
  /* Prevent line breaks and keep items on one line */
  scrollbar-width: none;
  /* For Firefox */
  margin-top: 20px;
  /* For Internet Explorer and Edge */
}



  .partners img:hover {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }


.partners .row {

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: flex-start;
  /* Align items to the start of the container */
  align-items: center;
  flex-wrap: nowrap;
  /* Prevent wrapping to maintain horizontal scrolling */
}

.partners .img {
  display: inline-flex;
  /* Ensure each item is inline */
  flex-direction: column;
  margin-right: 10px;
  /* Adjust spacing between items */
  transition: transform 0.3s ease-in-out;
  /* Smooth hover effect */
}

.partners img:hover {
  transform: scale(1.05);
  /* Slight zoom effect on hover */
}


a {
  text-decoration: none;
}
@media (max-width: 1200px) {
  .partners .row {
    grid-template-columns: repeat(3, 1fr);
    /* 3 columns for smaller screens */
  }
}

@media (max-width: 800px) {
  .partners .row {
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns for even smaller screens */
  }
}

@media (max-width: 500px) {
  .partners .row {
    grid-template-columns: repeat(2, 1fr);

    /* 1 column for very small screens */
  }
  .partners .innerContainer{
    width: 100% !important; 
  }
}

/* ------------ FORMS ------------ */

.forms {
  background: var(--blueColor);
  display: flex;
  width: 100%;
  height: 100%;
  align-items: inherit;
  color: white;
  margin-top: 80px;
}

.forms .left {
  background-color: rgb(15, 15, 15);
  display: flex;
  padding: 75px;
  flex: 1;
  border-top-right-radius: 100px;
  text-align: left;
  font-size: 30px;
  flex-direction: column;
  justify-content: space-between;
}

.forms .left h1 {
  font-weight: 600;
}

.forms input::placeholder {
  color: #00000050;
}

.forms .right {
  font-size: 20px;
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.forms .rightInner {
  width: 80%;
  margin-top: 7%;
  margin-bottom: 10%;
}

.forms .row {
  display: flex;
  flex-direction: row;
  margin: 20px 0 20px 0;
}

.forms .col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin: 0 30px 0 30px;
}

.forms .col label {
  align-self: flex-start;
  padding-bottom: 10px;
  font-size: 14px;
}

.forms input {
  padding: 10px 20px;
  font-size: 15px;
  border: solid white 1px;
  border-radius: 5px;
  flex: 1;
  background-color: #ffffff69;
  color: black;
  max-height: 21px;
}

#messageInput {
  background: #ffffff69;
  border: solid white 1px !important;
}

#messageInput:placeholder {
  color: #00000050;
}

.forms #message {
  text-align: left;
  width: 80%;
  padding-bottom: 20px;
}

.forms #messageInput {
  padding: 10px 10px 150px 10px;
}

.forms button {
  background-color: rgb(15, 15, 15);
  color: white;
  border: 1px solid var(--blueColor);
  border-radius: 10px;
  height: 40px;
}

.forms button:hover {
  filter: brightness(2);
}

.forms #checkbox {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

/* ------------ FOOTER ------------ */
.footerInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.footer {
  background: url("https://i.ibb.co/zXMxgwx/New-Project-2022-04-09-T161829-224-2.png");
  background-size: cover;
  flex-direction: column;
  padding: 20px 0;
  min-height: 300px;
  row-gap: 20px;
  justify-content: space-around;
}

.footer .inner {
  flex-direction: row;
  padding: 0;
  justify-content: space-around;
}

.footer h1 {
  color: #fff;
  font-size: 25px;
}

.footer svg {
  height: 30px;
  width: 30px;
}

.footer h3 {
  color: #fff;
  padding-top: 20px;
  font-size: 14px;
}

.footerSend #checkbox {
  display: flex;
  flex-direction: column;
  color: #fff;
  align-items: flex-start;
}

.footerSend #checkbox label {
  font-size: 14px;
}

.footer input {
  padding: 10px 20px;
  font-size: 15px;
  border: solid white 1px;
  border-radius: 5px;
  flex: 1;
  background-color: transparent;
  color: #fff;
  margin: 5px 0;
}

.footer h4 {
  color: #2c3b5c;
}

.footerSend .submit {
  text-align: left;
}

.footerSend .submit input {
  cursor: pointer;
  font-size: 14px;
}

.footerSend .submit input:hover {
  opacity: 0.5;
}

/* ------------ LOADING ------------ */
.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #fff;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}

.loading .bubble {
  width: 20px;
  height: 20px;
  background: var(--blueColor);
  border-radius: 50%;
  animation: jump 1s ease-in-out infinite;
}

.loading .bubble:nth-child(2) {
  animation-delay: 0.2s;
}

.loading .bubble:nth-child(3) {
  animation-delay: 0.4s;
}

.doneLoadingBG {
  background: #000;
  opacity: 0;
  visibility: hidden;
  animation: fadeOut 1s ease-in-out;
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
}

.doneLoadingLeft {
  width: 50vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  left: 0;
  background: #fff;
  visibility: hidden;
  animation: slideLeft 1s ease-in-out;
}

.doneLoadingRight {
  width: 50vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  right: 0;
  background: #fff;
  visibility: hidden;
  animation: slideRight 1s ease-in-out;
}

/* ---------------------- MAIN DROPDOWN MENU----------------------- */

.mobileNav {
  display: none;
}

.mainDropdown .drop {
  position: fixed;
  top: 80px;
  right: 0;
  width: 100vw;
  height: calc(100vh - 80px);
  flex-direction: column;
  z-index: 10000;
  align-items: flex-end !important;
  background: linear-gradient(-90deg, #000000c9 50%, #000000a3 70%, #000000a3);
}

.drop .sidebar {
  position: fixed;
  top: 80px;
  left: 50vw;
  width: 50vw;
  height: calc(100vh - 80px);
  flex-direction: column;
  z-index: 10000;
  min-height: 0 !important;
  animation: slideIn 0.4s ease-in-out;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url("https://i.ibb.co/zXMxgwx/New-Project-2022-04-09-T161829-224-2.png");
  background-size: 100% 100%;
}

.drop .sidebar .innerSidebar {
  display: flex;
  flex-direction: column;
}

.sidebar button {
  background: none;
  border: none;
  color: #fff;
  padding: 15px 0;
  margin: 10px 0;
  font-size: 16px;
  width: 100%;
}

.sidebar button:hover {
  background: #ffffff20;
}

.buttonWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 80px;
  cursor: pointer;
  transition: all 0.5 ease-in-out;
}

.hamburger {
  width: 30px;
  height: 2px;
  background: #000;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.hamburger::before,
.hamburger::after {
  content: "";
  position: absolute;
  width: 37px;
  height: 4px;
  background: #000;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.hamburger::before {
  transform: translate(-50%, -14px);
}

.hamburger::after {
  transform: translate(-50%, 14px);
}

.buttonWrapper.open .hamburger {
  transform: translateX(-50px);
  background: transparent;
}

.buttonWrapper.open .hamburger::before {
  transform: rotate(45deg) translate(21px, -21px);
}

.buttonWrapper.open .hamburger::after {
  transform: rotate(-45deg) translate(21px, 21px);
}

.hamburger2 {
  width: 30px;
  height: 2px;
  background: #000;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.hamburger2::before,
.hamburger2::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 2px;
  background: #000;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.hamburger2::before {
  transform: translate(0, -14px);
}

.hamburger2::after {
  transform: translate(0, 14px);
}

.buttonWrapper.open .hamburger2 {
  transform: translateX(-50px);
  background: transparent;
}

.buttonWrapper.open .hamburger2::before {
  transform: rotate(45deg) translate(35px, -35px);
}

.buttonWrapper.open .hamburger2::after {
  transform: rotate(-45deg) translate(35px, 35px);
}

/* ---------------------- SOCIALS----------------------- */
.mainSocials {
  position: fixed;
  bottom: 0;
  left: 20px;
  z-index: 1000;
}

.mainSocials .socials {
  flex-direction: column;
  row-gap: 30px;
}

.mainSocials .socials div {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.mainSocials .socials svg {
  fill: var(--darkText);
}

.socials {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 50px;
}

.socials svg {
  width: 25px;
  fill: #fff;
  cursor: pointer;
}

.socials div:hover {
  opacity: 0.8;
}

/* ------------ ANIMATIONS ------------ */
@keyframes slideIn {
  0% {
    left: 100vw;
  }

  100% {
    left: 50vw;
  }
}

@keyframes slideIn2 {
  0% {
    left: 100vw;
  }

  100% {
    left: 30vw;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 100%;
    visibility: visible;
  }

  100% {
    opacity: 0%;
    visibility: hidden;
  }
}

@keyframes slideLeft {
  0% {
    left: 0;
    visibility: visible;
  }

  100% {
    left: -50vw;
    visibility: hidden;
  }
}

@keyframes slideRight {
  0% {
    right: 0;
    visibility: visible;
  }

  100% {
    right: -50vw;
    visibility: hidden;
  }
}

@keyframes loadDown {
  0% {
    z-index: 8;
  }

  100% {
    z-index: 8;
    transform: translateY(calc(100% - 20px));
  }
}

@keyframes jump {
  0% {
    transform: translateY(0);
    opacity: 0.5;
  }

  50% {
    transform: translateY(-10px);
    opacity: 1;
  }

  100% {
    transform: translateY(0px);
    opacity: 0.5;
  }
}

/* ------------ MOBILE STYLES ------------ */

@media only screen and (max-width: 1650px) {
  .globeContainer canvas {
    position: absolute !important;
    right: -60vw !important;
    width: 2650px !important;
  }

  .forms .right {
    margin: 100px 100px 100px 100px;
  }
}

@media only screen and (max-width: 1550px) {
  .globeContainer canvas {
    position: absolute !important;
    right: -56vw !important;
    width: 2300px !important;
  }
}

@media only screen and (max-width: 1500px) {
  .forms .right {
    margin: 100px 50px 100px 50px;
  }

  .forms .left {
    padding: 50px;
  }
}

@media only screen and (max-width: 1450px) {
  .home p {
    font-size: 65px;
  }

  .globeContainer canvas {
    position: absolute !important;
    right: -57vw !important;
    bottom: -41vh !important;
    width: 2000px !important;
    height: 1200px !important;
  }

  .homeIMG {
    width: 700px !important;
    height: 700px !important;
  }
}

@media only screen and (max-width: 1350px) {
  .inner {
    width: 90% !important;
  }

  .forms .col {
    margin: 0 5px 0 5px;
  }
}

@media only screen and (max-width: 1250px) {
  .home p {
    font-size: 65px;
  }

  .globeContainer canvas {
    position: absolute !important;
    right: -57vw !important;
    bottom: -35vh !important;
    width: 1600px !important;
    height: 1000px !important;
  }

  .home .innerhome {
    width: 70%;
  }
}

@media only screen and (max-width: 1200px) {
  .homeIMG {
    opacity: 0.6;
  }

  .forms .row {
    flex-direction: column !important;
    margin: 0;
  }

  .forms .left {
    max-width: 30vw;
  }

  .forms .col {
    margin: 10px 5px;
  }

  .empty {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .header {
    display: none !important;
  }

  .mainSocials {
    display: none;
  }

  .mobileNav {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    background: #fff;
    z-index: 1000;
    box-shadow: 0 0 10px #0000002e;
  }

  .mobileNav .inner {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1050px) {
  .globeContainer canvas {
    position: absolute !important;
    right: -57vw !important;
    bottom: -35vh !important;
    width: 1400px !important;
    height: 1000px !important;
  }

  .home .innerhome {
    width: 80%;
  }

  .lawfirm {
    display: none;
  }

  .lawfirm2 {
    display: block;
  }
}

@media only screen and (max-width: 1000px) {
  .homeIMG {
    opacity: 0.3;
  }

  .tiltContainer {
    position: absolute;
    bottom: -15vh;
    right: -15vw;
  }

  .left h1 {
    font-size: 40px;
    line-height: 65px;
  }

  .forms .left {
    padding: 35px;
  }

  .cardsContainer {
    flex-direction: column;
  }

  .leftSection,
  .rightSection {
    margin: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 950px) {
  .services .container {
    width: 100%;
    max-width: 100%;
    margin: 20px 30px;
  }

  .services .leftSection,
  .services .rightSection {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about p {
    padding: 0;
  }

  .flex {
    min-width: 100%;
  }

  .metrics .container {
    row-gap: 25px;
  }

  .globeContainer canvas {
    position: absolute !important;
    right: -57vw !important;
    bottom: -35vh !important;
    width: 1200px !important;
    height: 1000px !important;
    opacity: 0.4;
  }

  .forms {
    flex-direction: column;
  }

  .forms .left {
    max-width: 100vw;
    border-radius: 0;
    row-gap: 35px;
  }

  .forms .right {
    margin: 50px;
  }

  .partners img {
    width: 150px;
  }
}

@media only screen and (max-width: 850px) {
  .home .innerhome {
    width: 90%;
  }

  .lawfirm2 {
    width: 150px;
  }

  .footer h1 {
    font-size: 22px !important;
  }

  .footer h3 {
    font-size: 14px !important;
    padding: 3px 0;
  }

  .footer h4 {
    font-size: 12px;
  }

  .footer svg {
    height: 40px;
    width: 40px;
  }
}

@media only screen and (max-width: 800px) {
  .globeContainer canvas {
    position: absolute !important;
    right: -57vw !important;
    bottom: -26vh !important;
    width: 1000px !important;
    height: 1000px !important;
  }

  .home p {
    font-size: 57px;
  }

  .home h2 {
    font-size: 20px;
  }

  .metrics h2 {
    font-size: 50px;
  }

  .partners .row {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 700px) {
  .footerSend {
    padding: 20px;
    width: 80%;
  }

  .footerInfo {
    width: 80%;
  }

  .tiltContainer {
    position: absolute;
    bottom: -20vh;
    right: -25vw;
  }

  .globeContainer canvas {
    position: absolute !important;
    right: -57vw !important;
    bottom: -26vh !important;
    width: 800px !important;
    height: 1000px !important;
  }

  .mobileNav h1 {
    font-size: 20px;
  }

  .about p {
    font-size: 30px;
    text-align: left;
    padding: 30px 0;
    line-height: 45px;
  }

  .about h1,
  .services h5,
  .forms .left h5 {
    text-align: left;
    font-size: 16px !important;
    line-height: 35px;
  }

  .services h5:first-child {
    font-size: 30px !important;
    line-height: 45px;
  }

  

  .forms .left h1 {
    font-size: 30px;
    line-height: 45px;
  }

  .forms .col label {
    font-size: 16px !important;
  }

  .drop .sidebar {
    left: 30vw;
    width: 70vw;
    animation: slideIn2 0.4s ease-in-out;
  }

  .footer .inner {
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .globeContainer canvas {
    width: 578px !important;
    opacity: 0.3;
  }

  .services h3 {
    line-height: 20px;
  }

  .metrics h2 {
    font-size: 45px;
  }

  .metrics h4 {
    font-size: 14px;
  }

  .lawfirm2 {
    display: none;
  }

  .services .inner {
    padding: 50px 0;
  }

  .about .inner {
    padding: 50px 0 25px;
  }

  .footer h1 {
    font-size: 25px !important;
  }

  .footer svg {
    height: 35px;
    width: 35px;
  }

  .tiltContainer {
    position: absolute;
    bottom: -8vh;
    right: -53vw;
  }

  .forms .right {
    margin: 0 !important;
  }

  .footerInfo,
  .footerSend #checkbox {
    align-items: center !important;
  }

  .footerSend .submit {
    text-align: center;
  }
}

@media only screen and (max-width: 400px) {
 

  .tiltContainer {
    right: -60vw;
  }

  .home .innerhome {
    row-gap: 10px;
    margin-top: -50px;
  }

  .home p {
    font-size: 50px;
  }

  .home h2 {
    font-size: 18px;
  }
}